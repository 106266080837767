import { AppLayout } from "components/AppLayout";
import { captureException } from "shared/sentry";
import { Loading } from "components/Loading";
import { Page } from "components/Page";
import { PageHead } from "components/PageHead";
import { staticText } from "content/staticText";
import { useAppContext } from "shared/AppContext";
import { useEffect, useState } from "react";
import { useFirestore } from "shared/firebase";
import Router from "next/router";

export const App = ({ pageId }) => {
  const { firebaseInstances } = useAppContext();
  const { error, loading, data } = useFirestore({
    database: firebaseInstances.database,
    path: `pagesContent/${pageId}`,
  });

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => setIsEditing(false), [pageId]);

  useEffect(() => {
    const onBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = staticText.areYouSureClose;
      return staticText.areYouSureClose;
    };

    const onRouteChangeStart = () => {
      // Throws error for now, since there is no better way, see: https://github.com/zeit/next.js/issues/2476

      if (!confirm(staticText.areYouSureClose)) {
        Router.router.abortComponentLoad();
        Router.router.events.emit("routeChangeError"); // For NProgress to stop the loading indicator
        throw new Error(
          "Abort page navigation, ignore this error, see: https://github.com/zeit/next.js/issues/2476"
        );
      }
    };

    if (isEditing) {
      window.addEventListener("beforeunload", onBeforeUnload);
      Router.router.events.on("routeChangeStart", onRouteChangeStart);
    }

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
      Router.router.events.off("routeChangeStart", onRouteChangeStart);
    };
  }, [isEditing]);

  if (error) {
    captureException(error);
  }

  return (
    <>
      <PageHead
        title={(data || {}).title}
        description={data || {}.description}
      />
      <AppLayout isEditing={isEditing} pageId={pageId}>
        {loading ? (
          <Loading />
        ) : data ? (
          <Page {...{ data, pageId, isEditing, setIsEditing }} />
        ) : null}
      </AppLayout>
    </>
  );
};
