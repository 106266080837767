import { AddIcon, DeleteIcon, EditIcon } from 'components/Icons'
import { Button } from 'components/Button'
import { Image } from 'components/Image'
import { ImageOverlay } from 'components/ImageOverlay'
import { useState } from 'react'
import cx from 'classnames'
import styles from './Hero.module.css'

export const Hero = ({ isEditing, hero, setHero }) => {
  const [showOverlay, setShowOverlay] = useState(false)

  const onSave = (value) => {
    setHero(value)
    setShowOverlay(false)
  }

  return (
    <>
      <div className={cx(styles.container, { [styles.isEditing]: isEditing })}>
        {isEditing && (
          <div className={styles.editButtonContainer}>
            <Button filled={!!hero} onClick={() => setShowOverlay(true)} icon={hero ? <EditIcon /> : <AddIcon />}>
              Afbeelding {hero ? 'wijzigen' : 'toevoegen'}
            </Button>
            {hero && (
              <Button filled onClick={() => setHero(null)} icon={<DeleteIcon />}>
                Afbeelding verwijderen
              </Button>
            )}
          </div>
        )}
        {hero && (
          <div className={styles.imageContainer}>
            <Image content={hero} />
          </div>
        )}
      </div>
      {showOverlay && (
        <ImageOverlay
          content={hero}
          disableCaption
          disableSize
          info="Minimale afmetingen: 1920 x 1080 pixels"
          onClose={() => setShowOverlay(false)}
          onSave={onSave}
        />
      )}
    </>
  )
}
