import { Button } from 'components/Button'
import { CloseIcon, SaveIcon } from 'components/Icons'
import { Overlay } from 'components/Overlay'
import { RichText } from 'components/RichText'
import { staticText } from 'content/staticText'
import styles from './WantToSaveOverlay.module.css'

export const WantToSaveOverlay = ({ onClose, onSave, onUndo }) => (
  <Overlay onClose={onClose}>
    <RichText>
      <h2>{staticText.youHaventSaved}</h2>
      <p>{staticText.saveOrUndo}</p>
    </RichText>

    <div className={styles.buttonsContainer}>
      <Button icon={<SaveIcon />} onClick={onSave}>
        {staticText.save}
      </Button>
      <Button icon={<CloseIcon />} onClick={onUndo}>
        {staticText.undoChanges}
      </Button>
    </div>
  </Overlay>
)
