import { AddIcon, DeleteIcon, SaveIcon } from 'components/Icons'
import { Button } from 'components/Button'
import { deepCopy } from 'shared/deepCopy'
import { Input } from 'components/Input'
import { Overlay } from 'components/Overlay'
import { useState } from 'react'
import styles from './ButtonsOverlay.module.css'

export const ButtonsOverlay = ({ onClose, items, onSave }) => {
  const [localItems, setLocalItems] = useState(items)

  const onRowChange = (value, index) => {
    const newItems = deepCopy(localItems)
    newItems[index] = { ...newItems[index], ...value }
    setLocalItems(newItems)
  }

  const addRow = () => {
    const newItems = deepCopy(localItems)
    newItems.push(newItems[newItems.length - 1])
    setLocalItems(newItems)
  }

  const deleteRow = (index) => {
    const newItems = deepCopy(localItems)
    newItems.splice(index, 1)
    setLocalItems(newItems)
  }

  return (
    <Overlay onClose={onClose}>
      <div className={styles.group}>
        {localItems.map((item, index) => (
          <Row
            key={index}
            onChange={(value) => onRowChange(value, index)}
            onDelete={() => deleteRow(index)}
            {...item}
          />
        ))}

        <Button icon={<AddIcon />} onClick={addRow}>
          Button toevoegen
        </Button>
      </div>
      <Button icon={<SaveIcon />} onClick={() => onSave({ items: localItems })}>
        Opslaan
      </Button>
    </Overlay>
  )
}

const Row = ({ href, label, onChange, onDelete }) => (
  <div className={styles.row}>
    <Input label="Label" onChange={(e) => onChange({ label: e.target.value })} type="text" value={label} />
    <Input
      label="Link"
      onChange={(e) => onChange({ href: e.target.value })}
      type="text"
      value={href}
      placeholder="https://www.google.com/"
    />

    <div>
      <button className={styles.deleteButton} onClick={onDelete}>
        <span className={styles.deleteButtonInner}>
          <DeleteIcon />
        </span>
      </button>
    </div>
  </div>
)
