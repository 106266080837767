import styles from './LightBox.module.css'
import { useState } from 'react'

export const LightBox = ({ children }) => {
  const [visible, setVisible] = useState(false)

  return (
    <div onClick={() => setVisible(!visible)}>
      <div className={styles.children}>{children}</div>
      {visible && (
        <div className={styles.lightBox}>
          <div className={styles.background} />
          <div className={styles.content}>{children}</div>
        </div>
      )}
    </div>
  )
}
