import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { Overlay } from 'components/Overlay'
import { S, M, L, FULL } from 'shared/sizes'
import { SaveIcon } from 'components/Icons'
import { Select } from 'components/Select'
import { staticText } from 'content/staticText'
import { useState } from 'react'
import styles from './VideoOverlay.module.css'

export const VideoOverlay = ({ onClose, src, size, onSave }) => {
  const [localSize, setLocalSize] = useState(size || S)
  const [localSrc, setLocalSrc] = useState(src)

  const sizeOptions = [
    { value: S, label: staticText.small },
    { value: M, label: staticText.medium },
    { value: L, label: staticText.large },
    { value: FULL, label: staticText.fullWidth },
  ]

  return (
    <Overlay onClose={onClose}>
      <div className={styles.group}>
        <Select
          label={staticText.size}
          onChange={(e) => setLocalSize(e.target.value)}
          options={sizeOptions}
          value={localSize}
        />
      </div>
      <div className={styles.group}>
        <Input
          label={'Youtube of Vimeo url'}
          onChange={(e) => setLocalSrc(e.target.value)}
          type="text"
          value={localSrc || ''}
        />
      </div>
      <Button icon={<SaveIcon />} onClick={() => onSave({ src: localSrc, size: localSize })}>
        Opslaan
      </Button>
    </Overlay>
  )
}
