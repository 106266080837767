import { BoldIcon, H2Icon, H3Icon, H4Icon, ItalicIcon, LinkIcon, OlIcon, UlIcon } from 'components/Icons'
import { createContentWithId } from 'shared/createContentWithId'
import { Editable, withReact, Slate } from 'slate-react'
import { Editor, Transforms, createEditor, Range } from 'slate'
import { LinkOverlay } from 'components/LinkOverlay'
import { RichText } from 'components/RichText'
import { Toolbar, ToolbarGroup, ToolbarButton } from 'components/Toolbar'
import { useCallback, useMemo, useState } from 'react'
import isHotkey from 'is-hotkey'

const INLINE_TYPE = ['link']
const LIST_TYPES = ['ul', 'ol']
const MARK_TYPES = ['bold', 'italic']

export const RichTextEditor = ({ isEditing, content, setContent }) => {
  const [overlay, setOverlay] = useState(null)

  const editor = useMemo(() => withInlineAndInsertData(withReact(createEditor())), [])
  const renderElement = useCallback((props) => <Element {...props} />, [])
  const renderLeaf = useCallback((props) => <Leaf {...props} />, [])

  const onBold = () => toggleMark(editor, 'bold')
  const onItalic = () => toggleMark(editor, 'italic')
  const onH2 = () => toggleBlock(editor, 'h2')
  const onH3 = () => toggleBlock(editor, 'h3')
  const onH4 = () => toggleBlock(editor, 'h4')
  const onOl = () => toggleBlock(editor, 'ol')
  const onUl = () => toggleBlock(editor, 'ul')

  const onLink = () => {
    const { selection } = editor

    if (!selection) {
      return
    }

    /* Temporary delete link if is active, cause replacing link is not working */
    if (isLinkActive(editor, selection)) {
      Transforms.unwrapNodes(editor, { at: selection, mode: 'all', match: (n) => n.type === 'link' })
    } else {
      const fragment = Editor.fragment(editor, selection)
      const firstLink = (fragment[0].children || []).find(({ type }) => type === 'link')
      const { url } = firstLink || {}

      setOverlay({ type: 'link', selection, url })
    }
  }

  const disabledForTypes = (editor, activeItems) =>
    activeItems.some((item) => {
      const isMark = MARK_TYPES.indexOf(item) > -1
      const isLink = item === 'link'

      return isMark ? isMarkActive(editor, item) : isLink ? isLinkActive(editor) : isBlockActive(editor, item)
    })

  const boldItalicLinkDisabled = (editor) => disabledForTypes(editor, ['h2', 'h3'])

  const checkHotkeys = (e) => {
    const hotkeyFunctions = {
      'mod+b': boldItalicLinkDisabled(editor) ? (_) => _ : onBold,
      'mod+i': boldItalicLinkDisabled(editor) ? (_) => _ : onItalic,
      'mod+2': onH2,
      'mod+3': onH3,
    }

    for (const hotkey in hotkeyFunctions) {
      if (isHotkey(hotkey, e)) {
        e.preventDefault()
        const fn = hotkeyFunctions[hotkey]
        fn()
      }
    }
  }

  const checkEnters = (e) => {
    if (e.key === 'Enter' && e.shiftKey) {
      if (['paragraph', '', null, undefined].some((x) => isBlockActive(editor, x))) {
        e.preventDefault()
        Transforms.insertText(editor, '\n')
      }
    }

    if (e.key === 'Enter') {
      if (['h2', 'h3'].some((x) => isBlockActive(editor, x))) {
        e.preventDefault()
        Transforms.insertNodes(editor, { children: [{ text: '' }] })
      }

      if (['ol', 'ul'].some((x) => isBlockActive(editor, x))) {
        const { anchor, focus } = editor.selection
        const newAnchor = { ...anchor, offset: 0 }
        const newFocus = { ...focus, offset: 1000 }
        const newSelection = { anchor: newAnchor, focus: newFocus }

        const fragment = Editor.fragment(editor, newSelection)
        const value = fragment[0].children[0].children[0].text

        if (value === '') {
          e.preventDefault()
          Transforms.unwrapNodes(editor, { match: (n) => LIST_TYPES.includes(n.type), split: true })
          Transforms.setNodes(editor, { type: 'paragraph' })
        }
      }
    }
  }

  const onKeyDown = (e) => {
    if (!isEditing) {
      return
    }

    checkHotkeys(e)
    checkEnters(e)
  }

  return (
    <>
      <Slate editor={editor} value={content} onChange={setContent}>
        {isEditing && (
          <Toolbar>
            <ToolbarGroup>
              <ToolbarButton
                icon={<BoldIcon />}
                isActive={(editor) => isMarkActive(editor, 'bold')}
                isDisabled={boldItalicLinkDisabled}
                onClick={onBold}
                title="Bold"
              />
              <ToolbarButton
                icon={<ItalicIcon />}
                isActive={(editor) => isMarkActive(editor, 'italic')}
                isDisabled={boldItalicLinkDisabled}
                onClick={onItalic}
                title="Italic"
              />
              <ToolbarButton
                icon={<LinkIcon />}
                isActive={(editor) => isLinkActive(editor)}
                isDisabled={boldItalicLinkDisabled}
                onClick={onLink}
                title="Link"
              />
            </ToolbarGroup>
            <ToolbarGroup>
              <ToolbarButton
                icon={<H2Icon />}
                isActive={(editor) => isBlockActive(editor, 'h2')}
                onClick={onH2}
                title="Heading 2"
              />
              <ToolbarButton
                icon={<H3Icon />}
                isActive={(editor) => isBlockActive(editor, 'h3')}
                onClick={onH3}
                title="Heading 3"
              />
              <ToolbarButton
                icon={<H4Icon />}
                isActive={(editor) => isBlockActive(editor, 'h4')}
                onClick={onH4}
                title="Heading 4"
              />
              <ToolbarButton
                icon={<OlIcon />}
                isActive={(editor) => isBlockActive(editor, 'ol')}
                onClick={onOl}
                title="Lijst (nummers)"
              />
              <ToolbarButton
                icon={<UlIcon />}
                isActive={(editor) => isBlockActive(editor, 'ul')}
                onClick={onUl}
                title="Lijst (bullets)"
              />
            </ToolbarGroup>
          </Toolbar>
        )}
        <RichText>
          <Editable
            onKeyDown={onKeyDown}
            placeholder={isEditing ? 'Begin met typen…' : null}
            readOnly={!isEditing}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
          />
        </RichText>
      </Slate>
      {overlay && overlay.type === 'link' && (
        <LinkOverlay
          onClose={() => setOverlay(null)}
          onRemove={() => onLinkRemove({ selection: overlay.selection })}
          onSave={({ url, target }) => onLinkSave({ url, target, selection: overlay.selection })}
          url={overlay.url}
        />
      )}
    </>
  )

  async function onLinkRemove({ selection }) {
    Transforms.unwrapNodes(editor, { at: selection, match: (n) => n.type === 'link' })
    setOverlay(null)
  }

  async function onLinkSave({ url, target, selection }) {
    wrapLink(editor, { url, target, selection })
    setOverlay(null)
  }
}

const withInlineAndInsertData = (editor) => {
  const { isInline, insertData } = editor

  editor.isInline = (element) => (INLINE_TYPE.indexOf(element.type) > -1 ? true : isInline(element))
  editor.insertData = (data) => {
    const text = data.getData('text/plain')

    if (!text) {
      return insertData(data)
    }

    const lines = text.split('\n')
    lines.forEach((line, index) =>
      index === 0 ? Transforms.insertText(editor, line) : Transforms.insertNodes(editor, { children: [{ text: line }] })
    )
  }

  return editor
}

const toggleBlock = (editor, format) => {
  const isList = LIST_TYPES.includes(format)
  const isActive = isBlockActive(editor, format)

  Transforms.unwrapNodes(editor, { match: (n) => LIST_TYPES.includes(n.type), split: true })
  Transforms.setNodes(editor, { type: isActive ? 'paragraph' : isList ? 'list-item' : format })

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, { match: (n) => n.type === format })
  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const isLinkActive = (editor, selection) => {
  const [link] = Editor.nodes(editor, { match: (n) => n.type === 'link', at: selection })
  return !!link
}

const wrapLink = (editor, { url, target, selection }) => {
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link = createContentWithId({ type: 'link', url, target, children: isCollapsed ? [{ text: url }] : [] })

  /* Temporary disable cause replacing link does not work yet */
  // if (isLinkActive(editor, selection)) {
  //   Transforms.unwrapNodes(editor, { at: selection, mode: 'all', match: n => n.type === 'link' })
  // }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link, { at: selection })
  } else {
    Transforms.wrapNodes(editor, link, { split: true, at: selection })
    Transforms.collapse(editor, { edge: 'end' })
  }
}

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'h2':
      return <h2 {...attributes}>{children}</h2>
    case 'h3':
      return <h3 {...attributes}>{children}</h3>
    case 'h4':
      return <h4 {...attributes}>{children}</h4>
    case 'link':
      return (
        <a {...attributes} href={element.url} target={element.target || '_self'} rel="noopener noreferrer">
          {children}
        </a>
      )
    case 'ul':
      return <ul {...attributes}>{children}</ul>
    case 'ol':
      return <ol {...attributes}>{children}</ol>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    default:
      return <p {...attributes}>{children}</p>
  }
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  return <span {...attributes}>{children}</span>
}
