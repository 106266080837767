import styles from './Video.module.css'

export const Video = ({ content }) => {
  const embedUrl = getEmbedUrl(content.src)

  return (
    <div className={styles.container}>
      <iframe
        className={styles.video}
        src={embedUrl}
        frameBorder="0"
        allow="autoplay"
        allow="encrypted-media"
        allowFullScreen
      />
    </div>
  )

  function getEmbedUrl(src) {
    src = src.trim()
    src = src.replace('-nocookie', '')
    src = src.replace('/www.', '/')

    if (/youtube|youtu\.be|y2u\.be|i.ytimg\./.test(src)) {
      return `https://youtube.com/embed/${getYoutubeId(src)}`
    }

    if (/vimeo/.test(src)) {
      return `https://player.vimeo.com/video/${getVimeoId(src)}`
    }

    return src
  }

  function getVimeoId(src) {
    const vimeoPipe = [
      'https?://vimeo.com/[0-9]+',
      'https?://player.vimeo.com/video/[0-9]+$',
      'https?://vimeo.com/channels',
      'groups',
      'album',
    ].join('|')
    const vimeoRegex = new RegExp(vimeoPipe, 'gim')

    let id
    let arr

    if (src.indexOf('#') > -1) {
      src = src.split('#')[0]
    }

    if (src.indexOf('?') > -1 && src.indexOf('clip_id=') === -1) {
      src = src.split('?')[0]
    }

    if (vimeoRegex.test(src)) {
      arr = src.split('/')

      if (arr && arr.length) {
        id = arr.pop()
      }
    } else if (/clip_id=/gim.test(src)) {
      arr = src.split('clip_id=')

      if (arr && arr.length) {
        id = arr[1].split('&')[0]
      }
    }

    return id
  }

  function getYoutubeId(src) {
    const shortcode = /youtube:\/\/|https?:\/\/youtu\.be\/|http:\/\/y2u\.be\//g
    const inlinev = /\/v\/|\/vi\//g
    const parameterv = /v=|vi=/g
    const parameterwebp = /\/an_webp\//g
    const embedreg = /\/embed\//g
    const usernamereg = /\/user\/([a-zA-Z0-9]*)$/g
    const userreg = /\/user\/(?!.*videos)/g
    const attrreg = /\/attribution_link\?.*v%3D([^%&]*)(%26|&|$)/

    if (shortcode.test(src)) {
      const shortcodeid = src.split(shortcode)[1]
      return stripParameters(shortcodeid)
    }

    if (inlinev.test(src)) {
      const inlineid = src.split(inlinev)[1]
      return stripParameters(inlineid)
    }

    if (parameterv.test(src)) {
      const arr = src.split(parameterv)
      return arr[1].split('&')[0]
    }

    if (parameterwebp.test(src)) {
      const webp = src.split(parameterwebp)[1]
      return stripParameters(webp)
    }

    if (embedreg.test(src)) {
      const embedid = src.split(embedreg)[1]
      return stripParameters(embedid)
    }

    if (usernamereg.test(src)) {
      return undefined
    }

    if (userreg.test(src)) {
      const elements = src.split('/')
      return stripParameters(elements.pop())
    }

    if (attrreg.test(src)) {
      return src.match(attrreg)[1]
    }

    function stripParameters(src) {
      if (src.indexOf('?') > -1) {
        return src.split('?')[0]
      }

      if (src.indexOf('/') > -1) {
        return src.split('/')[0]
      }

      return src
    }
  }
}
