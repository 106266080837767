import { hexToRgb, hexToCmyk, hexToHsl } from "shared/colors";
import cx from "classnames";
import styles from "./Colors.module.css";

export const Colors = ({ content }) => (
  <div className={styles.container}>
    {content.items.map((color, index) => (
      <ColorSquare key={index} {...color} />
    ))}
  </div>
);

function ColorSquare({ hex, cmyk, cmyku, pantone, title }) {
  const [a, b, lightness] = hexToHsl(hex);
  const isInverted = lightness > 0.85;

  const colors = [
    { label: "HEX", value: hex },
    { label: "RGB", value: hexToRgb(hex).join(", ") },
    { label: "CMYK (c)", value: cmyk || hexToCmyk(hex).join(", ") },
    { ...(cmyku ? { label: "CMYK (u)", value: cmyku } : {}) },
    { label: "Pantone", value: pantone },
  ];

  return (
    <div className={styles.colorSquareContainer}>
      <div
        className={cx(styles.colorSquare, { [styles.inverted]: isInverted })}
        style={{ backgroundColor: hex }}
      >
        <div className={styles.title}>{title}</div>
        {colors
          .filter(({ value }) => !!value)
          .map(({ label, value }, index) => (
            <div key={index} className={styles.colorValueContainer}>
              <div>{label}</div>
              <div className={styles.colorValue}>{value}</div>
            </div>
          ))}
      </div>
    </div>
  );
}
