import { useEffect, useRef } from "react";
import { iframeResize } from "iframe-resizer";
import styles from "./ComponentIframe.module.css";

export const ComponentIframe = ({ content }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    if (iframeRef.current) {
      iframeResize({}, iframeRef.current);
    }
  }, [iframeRef]);

  return (
    <ChildrenContainer>
      <iframe
        className={styles.iframe}
        frameBorder="0"
        ref={iframeRef}
        src={content.src}
        title="Een iframe met een component."
      />
    </ChildrenContainer>
  );
};

const ChildrenContainer = ({ children }) => {
  const backgroundRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const onResize = () => {
      containerRef.current.style.width = null;
    };

    window.addEventListener("resize", onResize, false);

    return () => window.removeEventListener("resize", onResize, false);
  }, []);

  return (
    <div ref={backgroundRef} className={styles.background}>
      <div ref={containerRef} className={styles.container}>
        <div className={styles.children}>{children}</div>
        <div
          className={styles.dragButton}
          onMouseDown={(e) =>
            onDragMouseDown({
              clientX: e.clientX,
              element: containerRef.current,
              maxWidthElement: backgroundRef.current,
            })
          }
        >
          <DragIcon />
        </div>
      </div>
    </div>
  );
};

const DragIcon = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.dragIcon}
    {...props}
  >
    <path d="M13 7H11V17H13V7Z" />
    <path d="M5.99996 7.75732L7.4218 9.17154L5.58572 11L9.99996 11V13L5.58579 13L7.41418 14.8284L5.99996 16.2426L1.75732 12L5.99996 7.75732Z" />
    <path d="M18 16.2427L16.5858 14.8285L18.4143 13H14V11L18.4142 11L16.5858 9.17161L18 7.75739L22.2427 12L18 16.2427Z" />
  </svg>
);

function onDragMouseDown({ clientX, element, maxWidthElement }) {
  const minWidth = 360;
  const maxWidth = parseInt(maxWidthElement.getBoundingClientRect().width, 10);
  let startX = clientX;
  let startWidth = parseInt(element.getBoundingClientRect().width, 10);

  const onDrag = ({ clientX }) => {
    const targetWidth = startWidth + clientX - startX;
    const newWidth =
      targetWidth < minWidth
        ? minWidth
        : targetWidth > maxWidth
        ? maxWidth
        : targetWidth;
    element.style.width = `${newWidth}px`;
  };

  const onDragEnd = () => {
    window.removeEventListener("mousemove", onDrag, false);
    window.removeEventListener("mouseup", onDragEnd, false);
  };

  window.addEventListener("mousemove", onDrag, false);
  window.addEventListener("mouseup", onDragEnd, false);
}
