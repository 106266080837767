import { Button } from 'components/Button'
import { CloseIcon, EditIcon } from 'components/Icons'
import { Overlay } from 'components/Overlay'
import { RichText } from 'components/RichText'
import { staticText } from 'content/staticText'
import styles from './EditAnywayOverlay.module.css'

export const EditAnywayOverlay = ({ onClose, onEdit }) => (
  <Overlay onClose={onClose}>
    <RichText>
      <h2>{staticText.pageInEditMode}</h2>
      <p>{staticText.wantToEditAnyway}</p>
    </RichText>

    <div className={styles.buttonsContainer}>
      <Button icon={<EditIcon />} onClick={onEdit}>
        {staticText.edit}
      </Button>
      <Button icon={<CloseIcon />} onClick={onClose}>
        {staticText.cancel}
      </Button>
    </div>
  </Overlay>
)
