import {
  AddIcon,
  ButtonsIcon,
  ChevronIcon,
  CloseIcon,
  ColoursIcon,
  DeleteIcon,
  DividerIcon,
  DoDontIcon,
  EmbedIcon,
  GridIcon,
  IframeIcon,
  ImageIcon,
  TextIcon,
} from 'components/Icons'
import { arrayMove } from 'shared/arrayMove'
import { Buttons } from 'components/Buttons'
import { ButtonsOverlay } from 'components/ButtonsOverlay'
import { Cards } from 'components/Cards'
import { CardsOverlay } from 'components/CardsOverlay'
import { Colors } from 'components/Colors'
import { ColorsOverlay } from 'components/ColorsOverlay'
import { ComponentIframe } from 'components/ComponentIframe'
import { ComponentIframeOverlay } from 'components/ComponentIframeOverlay'
import { createContentWithId } from 'shared/createContentWithId'
import { Divider } from 'components/Divider'
import { DoDont } from 'components/DoDont'
import { DoDontOverlay } from 'components/DoDontOverlay'
import { Image } from 'components/Image'
import { ImageOverlay } from 'components/ImageOverlay'
import { MaxWidth } from 'components/MaxWidth'
import { RichTextEditor } from 'components/RichTextEditor'
import { S, FULL } from 'shared/sizes'
import { staticText } from 'content/staticText'
import { useState } from 'react'
import { Video } from 'components/Video'
import { VideoOverlay } from 'components/VideoOverlay'
import { WithEditButton } from 'components/WithEditButton'
import { WithTooltip } from 'components/WithTooltip'
import cx from 'classnames'
import styles from './Content.module.css'

const INITIAL_ID = 'INITIAL'
const TYPES_WITH_EDIT_BUTTON = ['buttons', 'componentIframe', 'cards', 'colors', 'doDont', 'image', 'video']

export const Content = ({ content, setContent, isEditing }) => {
  const [overlay, setOverlay] = useState(null)

  const updatePositions = ({ id, moveUp }) => {
    const currentIndex = content.findIndex((item) => item.id === id)
    const newIndexWithMinus = moveUp ? currentIndex - 1 : currentIndex + 1
    const newIndex = newIndexWithMinus < 0 ? 0 : newIndexWithMinus

    const newContent = arrayMove(content, currentIndex, newIndex)
    const newContentWithNewPositions = newContent.map((item, index) => ({ ...item, position: index * 100 }))

    setContent(newContentWithNewPositions)
  }

  const onAdd = ({ id, value }) => {
    const newContent = [...content]

    if (id === INITIAL_ID) {
      newContent.push(createContentWithId(value))
    } else {
      const currentIndex = content.findIndex((item) => item.id === id)
      newContent.splice(currentIndex + 1, 0, createContentWithId(value))
    }

    const newContentWithNewPositions = newContent.map((item, index) => ({ ...item, position: index * 100 }))
    setContent(newContentWithNewPositions)
  }

  const onDelete = ({ id }) => {
    const newContent = [...content]
    const currentIndex = newContent.findIndex((item) => item.id === id)

    newContent.splice(currentIndex, 1)
    const newContentWithNewPositions = newContent.map((item, index) => ({ ...item, position: index * 100 }))

    setContent(newContentWithNewPositions)
  }

  const updateContent = ({ id, contentValue }) => {
    const newContent = [...content]
    const currentIndex = newContent.findIndex((item) => item.id === id)
    newContent[currentIndex].content = contentValue

    setContent(newContent)
    setOverlay(null)
  }

  return (
    <div>
      {content
        .sort((a, b) => a.position - b.position)
        .map(({ id, type, content, position }) => (
          <ContentItemContainer
            id={id}
            isEditing={isEditing}
            key={id}
            onAdd={(value) => onAdd({ id, value })}
            onDelete={() => onDelete({ id })}
            onDown={() => updatePositions({ id, moveUp: false })}
            onEditButtonClick={TYPES_WITH_EDIT_BUTTON.includes(type) ? () => setOverlay({ id, type, content }) : null}
            onUp={() => updatePositions({ id, moveUp: true })}
            position={position}
            size={content.size}
          >
            <ContentItem
              updateContent={(contentValue) => updateContent({ id, contentValue })}
              type={type}
              content={content}
              isEditing={isEditing}
            />
          </ContentItemContainer>
        ))}
      {isEditing && content.length === 0 && (
        <ContentItemContainer isEditing={isEditing} onAdd={(value) => onAdd({ id: INITIAL_ID, value })}>
          <div className={styles.emptyText}>{staticText.fromComponent}</div>
        </ContentItemContainer>
      )}

      {overlay && overlay.type === 'video' && (
        <VideoOverlay
          onClose={() => setOverlay(null)}
          onSave={(contentValue) => updateContent({ id: overlay.id, contentValue })}
          size={overlay.content.size}
          src={overlay.content.src}
        />
      )}
      {overlay && overlay.type === 'image' && (
        <ImageOverlay
          content={overlay.content}
          onClose={() => setOverlay(null)}
          onSave={(contentValue) => updateContent({ id: overlay.id, contentValue })}
        />
      )}
      {overlay && overlay.type === 'doDont' && (
        <DoDontOverlay
          doImage={overlay.content.doImage}
          dontImage={overlay.content.dontImage}
          onClose={() => setOverlay(null)}
          onSave={(contentValue) => updateContent({ id: overlay.id, contentValue })}
          size={overlay.content.size}
        />
      )}
      {overlay && overlay.type === 'colors' && (
        <ColorsOverlay
          items={overlay.content.items}
          onClose={() => setOverlay(null)}
          onSave={(contentValue) => updateContent({ id: overlay.id, contentValue })}
          size={overlay.content.size}
        />
      )}
      {overlay && overlay.type === 'buttons' && (
        <ButtonsOverlay
          items={overlay.content.items}
          onClose={() => setOverlay(null)}
          onSave={(contentValue) => updateContent({ id: overlay.id, contentValue })}
        />
      )}
      {overlay && overlay.type === 'cards' && (
        <CardsOverlay
          backgroundColor={overlay.content.backgroundColor}
          cards={overlay.content.cards}
          onClose={() => setOverlay(null)}
          onSave={(contentValue) => updateContent({ id: overlay.id, contentValue })}
        />
      )}
      {overlay && overlay.type === 'componentIframe' && (
        <ComponentIframeOverlay
          onClose={() => setOverlay(null)}
          onSave={(contentValue) => updateContent({ id: overlay.id, contentValue })}
          size={overlay.content.size}
          src={overlay.content.src}
        />
      )}
    </div>
  )
}

const ContentItem = ({ type, content, isEditing, updateContent }) => {
  const props = { content, type, isEditing }

  switch (type) {
    case 'buttons':
      return <Buttons {...props} />
    case 'cards':
      return <Cards {...props} />
    case 'colors':
      return <Colors {...props} />
    case 'divider':
      return <Divider {...props} />
    case 'doDont':
      return <DoDont {...props} />
    case 'componentIframe':
      return <ComponentIframe {...props} />
    case 'image':
      return <Image {...props} />
    case 'richtext':
      return <RichTextEditor key={`RT-${isEditing}`} {...props} setContent={updateContent} />
    case 'video':
      return <Video {...props} />
    default:
      return <div />
  }
}

const ContentItemContainer = ({ children, isEditing, onEditButtonClick, size, onUp, onDown, onAdd, onDelete }) => {
  const shouldHaveEditButton = onEditButtonClick && isEditing
  const Wrapper = shouldHaveEditButton ? WithEditButton : 'div'
  const onWithEditButtonClick = shouldHaveEditButton ? onEditButtonClick : null

  return (
    <div className={styles.itemContainer}>
      <MaxWidth size={size || S}>
        <div className={isEditing ? styles.itemContainerBorder : ''}>
          <div className={styles.itemContainerTools}>
            {isEditing && <ContentItemTools onUp={onUp} onDown={onDown} onAdd={onAdd} onDelete={onDelete} />}
          </div>
        </div>
        <Wrapper onClick={onWithEditButtonClick}>{children}</Wrapper>
      </MaxWidth>
    </div>
  )
}

const ContentItemTools = ({ onUp, onDown, onAdd, onDelete }) => {
  const [showAdd, setShowAdd] = useState(false)

  const onAddWithClose = (x) => {
    onAdd(x)
    setShowAdd(false)
  }

  return (
    <div className={styles.contentItemTools}>
      {onUp && onDown && (
        <div className={styles.contentItemToolsContainer}>
          <div className={styles.contentItemToolsButtonContainer}>
            <button className={cx(styles.contentItemToolsButton, styles.up)} onClick={onUp}>
              <ChevronIcon />
            </button>
          </div>
          <div className={styles.contentItemToolsButtonContainer}>
            <button className={cx(styles.contentItemToolsButton, styles.down)} onClick={onDown}>
              <ChevronIcon />
            </button>
          </div>
        </div>
      )}
      <div className={styles.contentItemToolsContainer}>
        <button className={styles.contentItemToolsButton} onClick={onDelete}>
          <DeleteIcon />
        </button>
      </div>
      <div className={cx(styles.contentItemToolsContainer, { [styles.contentItemToolsContainerOpen]: showAdd })}>
        <button className={styles.contentItemToolsButton} onClick={() => setShowAdd(!showAdd)}>
          {showAdd ? <CloseIcon /> : <AddIcon />}
        </button>
        {showAdd && <AddButtons onAdd={onAddWithClose} />}
      </div>
    </div>
  )
}

const AddButtons = ({ onAdd }) => (
  <div className={styles.addButtons}>
    <div className={styles.addButtonDivider} />
    <WithTooltip text="Tekst">
      <button
        className={styles.addButton}
        onClick={() =>
          onAdd({
            type: 'richtext',
            content: [{ children: [{ text: '' }] }],
          })
        }
      >
        <TextIcon />
      </button>
    </WithTooltip>
    <div className={styles.addButtonDivider} />
    <WithTooltip text="Afbeelding">
      <button
        className={styles.addButton}
        onClick={() => onAdd({ type: 'image', content: { src: '/placeholder.jpg' } })}
      >
        <ImageIcon />
      </button>
    </WithTooltip>
    <WithTooltip text="Youtube/Vimeo">
      <button
        className={styles.addButton}
        onClick={() => onAdd({ type: 'video', content: { src: 'https://www.youtube.com/embed/NpEaa2P7qZI' } })}
      >
        <EmbedIcon />
      </button>
    </WithTooltip>
    <WithTooltip text="Do / Don't">
      <button
        className={styles.addButton}
        onClick={() =>
          onAdd({
            type: 'doDont',
            content: {
              doImage: { src: '/placeholder.jpg', alt: 'Placeholder' },
              dontImage: { src: '/placeholder.jpg', alt: 'Placeholder' },
            },
          })
        }
      >
        <DoDontIcon />
      </button>
    </WithTooltip>
    <WithTooltip text="Divider">
      <button className={styles.addButton} onClick={() => onAdd({ type: 'divider', content: {} })}>
        <DividerIcon />
      </button>
    </WithTooltip>
    <WithTooltip text="Buttons">
      <button
        className={styles.addButton}
        onClick={() =>
          onAdd({
            type: 'buttons',
            content: {
              items: [{ href: '#', label: 'Nieuwe button' }],
            },
          })
        }
      >
        <ButtonsIcon />
      </button>
    </WithTooltip>
    <WithTooltip text="Component">
      <button
        className={styles.addButton}
        onClick={() => onAdd({ type: 'componentIframe', content: { src: '/placeholder.jpg' } })}
      >
        <IframeIcon />
      </button>
    </WithTooltip>
    <WithTooltip text="Kleuren">
      <button
        className={styles.addButton}
        onClick={() =>
          onAdd({
            type: 'colors',
            content: {
              items: [
                { hex: '#000000', pantone: 'Black C', title: 'Black' },
                { hex: '#000000', pantone: 'Black C', title: 'Black' },
                { hex: '#000000', pantone: 'Black C', title: 'Black' },
              ],
            },
          })
        }
      >
        <ColoursIcon />
      </button>
    </WithTooltip>
    <WithTooltip text="Grid">
      <button
        className={styles.addButton}
        onClick={() =>
          onAdd({
            type: 'cards',
            content: {
              backgroundColor: '#fafafa',
              size: FULL,
              cards: [
                {
                  description: "Informatie over logo's.",
                  href: '/',
                  image: { src: '/placeholder.jpg' },
                  title: "Logo's",
                },
                {
                  description: 'Hier iets over lettertypes.',
                  href: '/',
                  image: { src: '/placeholder.jpg' },
                  title: 'Lettertypes',
                },
                {
                  description: 'Een korte samenvatting over downloads.',
                  href: '/',
                  image: { src: '/placeholder.jpg' },
                  title: 'Downloads',
                },
              ],
            },
          })
        }
      >
        <GridIcon />
      </button>
    </WithTooltip>
  </div>
)
