import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { Overlay } from 'components/Overlay'
import { RichText } from 'components/RichText'
import { SaveIcon, UnlockIcon } from 'components/Icons'
import { staticText } from 'content/staticText'
import { useEffect, useState } from 'react'
import styles from './PasswordProtectOverlay.module.css'

export const PasswordProtectOverlay = ({ hasPassword, onClose, onSave, onRemove }) => {
  const [error, setError] = useState(null)
  const [pw1, setPw1] = useState('')
  const [pw2, setPw2] = useState('')
  const [pwCorrect, setPwCorrect] = useState(false)

  useEffect(() => {
    if (pw1.length < 6) {
      setError(staticText.passwordProtectCharacters)
      setPwCorrect(false)
      return
    }

    if (pw1 !== pw2) {
      setError(staticText.passwordProtectShouldMatch)
      setPwCorrect(false)
      return
    }

    setError(null)
    setPwCorrect(true)
  }, [pw1, pw2])

  const onFormSave = (e) => {
    e.preventDefault()

    if (pwCorrect) {
      onSave(pw1)
    }
  }

  return (
    <Overlay onClose={onClose}>
      <RichText>
        <h2>{staticText.passwordProtectTitle}</h2>
        <p>{staticText.passwordProtectText}</p>
      </RichText>

      <form className={styles.group} onSubmit={onFormSave}>
        <div className={styles.group}>
          <Input
            label={staticText.passwordProtectInputLabel}
            onChange={(e) => setPw1(e.target.value)}
            type="password"
            value={pw1}
          />
        </div>

        <div className={styles.group}>
          <Input
            label={staticText.passwordProtectInputLabelRepeat}
            onChange={(e) => setPw2(e.target.value)}
            type="password"
            value={pw2}
          />
        </div>

        {error && <div className={styles.error}>{error}</div>}

        <Button icon={<SaveIcon />} onClick={onFormSave} disabled={!pwCorrect}>
          {staticText.save}
        </Button>
      </form>

      {hasPassword && (
        <>
          <RichText>
            <h2>{staticText.removePasswordTitle}</h2>
            <p>{staticText.removePasswordText}</p>
          </RichText>

          <Button icon={<UnlockIcon />} onClick={onRemove}>
            {staticText.removePassword}
          </Button>
        </>
      )}
    </Overlay>
  )
}
