export const pageTemplates = [
  {
    title: 'Algemeen',
    description: 'Een algemeen template om mee te beginnen',
    content: [
      {
        id: '1586347453223-f9iobz2en',
        position: 0,
        type: 'richtext',
        content: [
          { children: [{ text: 'Introductie' }], type: 'h2' },
          { children: [{ text: 'Een korte introductie voor de content op deze pagina.' }] },
          { children: [{ text: '[Onderwerp]' }], type: 'h2' },
          {
            children: [
              {
                text:
                  'Vertel kort iets over het onderwerp van deze pagina, laat bijvoorbeeld het logo of fotografie zien.',
              },
            ],
          },
        ],
      },
      { type: 'image', content: { src: '/placeholder.jpg' }, id: '1586347486883-oms226ss5', position: 100 },
      {
        id: '1586347495218-aa60jxjj7',
        position: 200,
        type: 'richtext',
        content: [
          { children: [{ text: 'Gebruik' }], type: 'h2' },
          {
            children: [
              {
                text:
                  'Vertel kort iets over hoe dit onderdeel gebruikt wordt binnen de organisatie. Probeer dit te laten zien met voorbeelden van hoe het wel- en niet moet.',
              },
            ],
          },
        ],
      },
      {
        type: 'doDont',
        content: {
          doImage: { src: '/placeholder.jpg', alt: 'Placeholder' },
          dontImage: { alt: 'Placeholder', src: '/placeholder.jpg' },
        },
        id: '1586347513028-tc4m0jm8q',
        position: 300,
      },
      {
        position: 400,
        type: 'richtext',
        content: [
          { type: 'h2', children: [{ text: 'Voorbeelden' }] },
          {
            children: [
              {
                text:
                  'Laat een aantal voorbeelden zien van hoe dit onderwerp al gebruikt wordt in de praktijk. Dat kan in tekst, video of afbeeldingen zijn.',
              },
            ],
          },
        ],
        id: '1586347518259-kqq5bha2m',
      },
      { type: 'image', content: { src: '/placeholder.jpg' }, id: '1586347540951-7c5evwsmo', position: 500 },
      {
        type: 'video',
        content: { src: 'https://www.youtube.com/embed/NpEaa2P7qZI' },
        id: '1586347543888-cetn8okbm',
        position: 600,
      },
      {
        type: 'richtext',
        content: [
          { type: 'h2', children: [{ text: 'Downloads' }] },
          { children: [{ text: 'Laat de bezoeker weten waar zij downloads kan vinden voor dit onderdeel.' }] },
          {
            children: [
              { text: '' },
              {
                children: [{ text: 'Download voorbeeld PDF' }],
                type: 'link',
                url: '/example.pdf',
                id: '1586347574635-qfmme95ge',
              },
              { text: '' },
            ],
          },
        ],
        id: '1586347552426-17cjfxcgf',
        position: 700,
      },
    ],
  },
  {
    title: 'Logo',
    description: 'Template om logogebruik binnen de organisatie mee vast te leggen.',
    content: [
      {
        id: '1586347453223-f9iobz2en',
        position: 0,
        content: [
          { children: [{ text: 'Introductie' }], type: 'h2' },
          { children: [{ text: 'Een korte introductie voor de content op deze pagina.' }] },
          { children: [{ text: 'Het Logo' }], type: 'h2' },
        ],
        type: 'richtext',
      },
      { content: { src: '/placeholder.jpg' }, type: 'image', id: '1586347486883-oms226ss5', position: 100 },
      {
        content: [
          { children: [{ text: 'Gebruik' }], type: 'h2' },
          {
            children: [
              {
                text:
                  'Vertel kort iets over hoe het logo gebruikt wordt binnen de organisatie. Probeer dit te laten zien met voorbeelden van hoe het wel- en niet moet.',
              },
            ],
          },
        ],
        type: 'richtext',
        id: '1586347495218-aa60jxjj7',
        position: 200,
      },
      {
        position: 300,
        content: {
          doImage: { alt: 'Placeholder', src: '/placeholder.jpg' },
          dontImage: { src: '/placeholder.jpg', alt: 'Placeholder' },
        },
        type: 'doDont',
        id: '1586347513028-tc4m0jm8q',
      },
      {
        content: [
          { type: 'h2', children: [{ text: 'Voorbeelden' }] },
          {
            children: [
              { text: 'Laat een aantal voorbeelden zien van hoe het logo gebruikt wordt binnen de organisatie.' },
            ],
          },
        ],
        type: 'richtext',
        id: '1586347518259-kqq5bha2m',
        position: 400,
      },
      { content: { src: '/placeholder.jpg' }, type: 'image', id: '1586347540951-7c5evwsmo', position: 500 },
      {
        position: 800,
        content: [
          { type: 'h2', children: [{ text: 'Downloads' }] },
          { children: [{ text: 'Laat de bezoeker weten waar zij downloads kan vinden voor dit onderdeel.' }] },
          {
            children: [
              { text: '' },
              {
                type: 'link',
                id: '1586347574635-qfmme95ge',
                url: '/example.pdf',
                children: [{ text: 'Download voorbeeld PDF' }],
              },
              { text: '' },
            ],
          },
        ],
        type: 'richtext',
        id: '1586347552426-17cjfxcgf',
      },
    ],
  },
  {
    title: 'Kleuren',
    description: 'Template om kleurgebruik binnen de organisatie mee vast te leggen.',
    content: [
      {
        type: 'richtext',
        content: [
          { children: [{ text: 'Introductie' }], type: 'h2' },
          { children: [{ text: 'Een korte introductie voor de content op deze pagina.' }] },
          { type: 'h2', children: [{ text: 'Primaire kleuren' }] },
          { children: [{ text: 'Vertel iets over de primaire kleuren.' }] },
        ],
        id: '1586347453223-f9iobz2en',
        position: 0,
      },
      {
        id: '1586348179327-7i3n95mhc',
        position: 100,
        type: 'colors',
        content: {
          items: [
            { hex: '#000000', title: 'Black', pantone: 'Black C' },
            { title: 'Black', pantone: 'Black C', hex: '#000000' },
            { title: 'Black', pantone: 'Black C', hex: '#000000' },
          ],
        },
      },
      {
        type: 'richtext',
        content: [
          { children: [{ text: 'Secundaire kleuren' }], type: 'h2' },
          { children: [{ text: 'Vertel iets over de secundaire kleuren.' }] },
        ],
        id: '1586347495218-aa60jxjj7',
        position: 300,
      },
      {
        position: 400,
        type: 'colors',
        content: {
          items: [
            { title: 'Black', pantone: 'Black C', hex: '#000000' },
            { hex: '#000000', title: 'Black', pantone: 'Black C' },
            { title: 'Black', pantone: 'Black C', hex: '#000000' },
          ],
        },
        id: '1586348175505-jrdb0z0of',
      },
      {
        type: 'richtext',
        content: [
          { children: [{ text: 'Gebruik' }], type: 'h2' },
          { children: [{ text: 'Vertel kort iets over hoe kleur gebruikt wordt binnen de organisatie.' }] },
        ],
        id: '1586348142573-kxr24r47x',
        position: 500,
      },
      {
        type: 'doDont',
        content: {
          doImage: { src: '/placeholder.jpg', alt: 'Placeholder' },
          dontImage: { src: '/placeholder.jpg', alt: 'Placeholder' },
        },
        id: '1586347513028-tc4m0jm8q',
        position: 600,
      },
      {
        type: 'richtext',
        content: [
          { children: [{ text: 'Voorbeelden' }], type: 'h2' },
          {
            children: [
              {
                text:
                  'Laat een aantal voorbeelden zien van hoe kleur gebruikt wordt binnen de organisatie. Dat kan in tekst, video of afbeeldingen zijn.',
              },
            ],
          },
        ],
        id: '1586347518259-kqq5bha2m',
        position: 700,
      },
      { type: 'image', content: { src: '/placeholder.jpg' }, id: '1586347540951-7c5evwsmo', position: 800 },
      {
        id: '1586347552426-17cjfxcgf',
        position: 1000,
        type: 'richtext',
        content: [
          { children: [{ text: 'Downloads' }], type: 'h2' },
          { children: [{ text: 'Laat de bezoeker weten waar zij downloads kan vinden voor dit onderdeel.' }] },
          {
            children: [
              { text: '' },
              {
                children: [{ text: 'Download voorbeeld PDF' }],
                type: 'link',
                url: '/example.pdf',
                id: '1586347574635-qfmme95ge',
              },
              { text: '' },
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Fotografie',
    description: 'Template om vast te leggen hoe fotografie moet worden gebruikt.',
    content: [
      {
        type: 'richtext',
        content: [
          { children: [{ text: 'Introductie' }], type: 'h2' },
          { children: [{ text: 'Een korte introductie voor de content op deze pagina.' }] },
        ],
        id: '1586347453223-f9iobz2en',
        position: 0,
      },
      { type: 'image', content: { src: '/placeholder.jpg' }, id: '1586347486883-oms226ss5', position: 100 },
      {
        type: 'richtext',
        content: [
          { type: 'h2', children: [{ text: 'Gebruik' }] },
          { children: [{ text: 'Vertel kort iets over hoe fotografie gebruikt wordt binnen de organisatie.' }] },
        ],
        id: '1586347495218-aa60jxjj7',
        position: 200,
      },
      {
        id: '1586347513028-tc4m0jm8q',
        position: 300,
        type: 'doDont',
        content: {
          doImage: { src: '/placeholder.jpg', alt: 'Placeholder' },
          dontImage: { src: '/placeholder.jpg', alt: 'Placeholder' },
        },
      },
      {
        position: 400,
        type: 'richtext',
        content: [
          { type: 'h2', children: [{ text: 'Voorbeelden' }] },
          {
            children: [
              { text: 'Laat een aantal voorbeelden zien van hoe fotografie gebruikt wordt binnen de organisatie.' },
            ],
          },
        ],
        id: '1586347518259-kqq5bha2m',
      },
      { id: '1586347540951-7c5evwsmo', position: 500, type: 'image', content: { src: '/placeholder.jpg' } },
      {
        type: 'richtext',
        content: [
          { type: 'h2', children: [{ text: 'Downloads' }] },
          { children: [{ text: 'Laat de bezoeker weten waar zij downloads kan vinden voor dit onderdeel.' }] },
          {
            children: [
              { text: '' },
              {
                type: 'link',
                url: '/example.pdf',
                id: '1586347574635-qfmme95ge',
                children: [{ text: 'Download voorbeeld PDF' }],
              },
              { text: '' },
            ],
          },
        ],
        id: '1586347552426-17cjfxcgf',
        position: 700,
      },
    ],
  },
  {
    title: 'Video',
    description: 'Template om videogebruik binnen de organisatie mee vast te leggen.',
    content: [
      {
        type: 'richtext',
        content: [
          { children: [{ text: 'Introductie' }], type: 'h2' },
          { children: [{ text: 'Een korte introductie voor de content op deze pagina.' }] },
        ],
        id: '1586347453223-f9iobz2en',
        position: 0,
      },
      {
        type: 'video',
        content: { src: 'https://www.youtube.com/embed/NpEaa2P7qZI' },
        id: '1586347486883-oms226ss5',
        position: 100,
      },
      {
        type: 'richtext',
        content: [
          { type: 'h2', children: [{ text: 'Gebruik' }] },
          { children: [{ text: 'Vertel kort iets over hoe video gebruikt wordt binnen de organisatie.' }] },
        ],
        id: '1586347495218-aa60jxjj7',
        position: 200,
      },
      {
        id: '1586347513028-tc4m0jm8q',
        position: 300,
        type: 'doDont',
        content: {
          doImage: { src: '/placeholder.jpg', alt: 'Placeholder' },
          dontImage: { src: '/placeholder.jpg', alt: 'Placeholder' },
        },
      },
      {
        position: 400,
        type: 'richtext',
        content: [
          { type: 'h2', children: [{ text: 'Voorbeelden' }] },
          {
            children: [
              { text: 'Laat een aantal voorbeelden zien van hoe video gebruikt wordt binnen de organisatie.' },
            ],
          },
        ],
        id: '1586347518259-kqq5bha2m',
      },
      {
        type: 'video',
        content: { src: 'https://www.youtube.com/embed/NpEaa2P7qZI' },
        id: '1586347543888-cetn8okbm',
        position: 500,
      },
      {
        type: 'richtext',
        content: [
          { type: 'h2', children: [{ text: 'Downloads' }] },
          { children: [{ text: 'Laat de bezoeker weten waar zij downloads kan vinden voor dit onderdeel.' }] },
          {
            children: [
              { text: '' },
              {
                type: 'link',
                url: '/example.pdf',
                id: '1586347574635-qfmme95ge',
                children: [{ text: 'Download voorbeeld PDF' }],
              },
              { text: '' },
            ],
          },
        ],
        id: '1586347552426-17cjfxcgf',
        position: 700,
      },
    ],
  },
  {
    title: 'Illustraties',
    description: 'Template om illustratiegebruik binnen de organisatie mee vast te leggen.',
    content: [
      {
        type: 'richtext',
        content: [
          { children: [{ text: 'Introductie' }], type: 'h2' },
          { children: [{ text: 'Een korte introductie voor de content op deze pagina.' }] },
        ],
        id: '1586347453223-f9iobz2en',
        position: 0,
      },
      { type: 'image', content: { src: '/placeholder.jpg' }, id: '1586347486883-oms226ss5', position: 100 },
      {
        type: 'richtext',
        content: [
          { type: 'h2', children: [{ text: 'Gebruik' }] },
          { children: [{ text: 'Vertel kort iets over hoe illustraties gebruikt worden binnen de organisatie.' }] },
        ],
        id: '1586347495218-aa60jxjj7',
        position: 200,
      },
      {
        id: '1586347513028-tc4m0jm8q',
        position: 300,
        type: 'doDont',
        content: {
          doImage: { src: '/placeholder.jpg', alt: 'Placeholder' },
          dontImage: { src: '/placeholder.jpg', alt: 'Placeholder' },
        },
      },
      {
        position: 400,
        type: 'richtext',
        content: [
          { type: 'h2', children: [{ text: 'Voorbeelden' }] },
          {
            children: [
              { text: 'Laat een aantal voorbeelden zien van hoe illustraties gebruikt worden binnen de organisatie.' },
            ],
          },
        ],
        id: '1586347518259-kqq5bha2m',
      },
      { id: '1586347540951-7c5evwsmo', position: 500, type: 'image', content: { src: '/placeholder.jpg' } },
      {
        type: 'richtext',
        content: [
          { type: 'h2', children: [{ text: 'Downloads' }] },
          { children: [{ text: 'Laat de bezoeker weten waar zij downloads kan vinden voor dit onderdeel.' }] },
          {
            children: [
              { text: '' },
              {
                type: 'link',
                url: '/example.pdf',
                id: '1586347574635-qfmme95ge',
                children: [{ text: 'Download voorbeeld PDF' }],
              },
              { text: '' },
            ],
          },
        ],
        id: '1586347552426-17cjfxcgf',
        position: 700,
      },
    ],
  },
]
