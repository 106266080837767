import { Button } from 'components/Button';
import { captureException } from 'shared/sentry';
import { Content } from 'components/Content';
import { deepCompare } from 'shared/deepCompare';
import { deepCopy } from 'shared/deepCopy';
import { EditAnywayOverlay } from 'components/EditAnywayOverlay';
import { EditBar } from 'components/EditBar';
import { EditIcon, LockIcon } from 'components/Icons';
import { Hero } from 'components/Hero';
import { PageTitle } from 'components/PageTitle';
import { PasswordProtectOverlay } from 'components/PasswordProtectOverlay';
import { staticText } from 'content/staticText';
import { TemplatesContainer } from 'components/Templates';
import { UnlockForm } from 'components/UnlockForm';
import { updatePageContent, updatePageIndex } from 'shared/firebase';
import { useAppContext } from 'shared/AppContext';
import { useEffect, useState } from 'react';
import { WantToSaveOverlay } from 'components/WantToSaveOverlay';
import isHotkey from 'is-hotkey';
import styles from './Page.module.css';

export const Page = ({ data, pageId, isEditing, setIsEditing }) => {
  const { firebaseInstances, setNotification, settings, user } = useAppContext();
  const fontFamily = ((settings || {}).fontFamily || {}).value || 'inherit';
  const savePage = (data) => {
    const pageIndexData = {};
    if (data.title) pageIndexData.title = data.title;
    if (typeof data.password !== 'undefined') pageIndexData.password = data.password;

    return Promise.all([
      updatePageContent({ data, database: firebaseInstances.database, pageId }),
      updatePageIndex({
        id: pageId,
        database: firebaseInstances.database,
        data: pageIndexData,
      }),
    ]);
  };
  const setIsBeingEditedBy = (email) =>
    updatePageContent({
      data: { isBeingEditedBy: email },
      database: firebaseInstances.database,
      pageId,
    });

  const [content, setContent] = useState([]);
  const [hero, setHero] = useState(data.hero || null);
  const [isLocked, setIsLocked] = useState(false);
  const [title, setTitle] = useState(data.title || null);
  const [unlockFormError, setUnlockFormError] = useState(false);
  const [visibleOverlay, setVisibleOverlay] = useState(false);

  const showTemplatesContainer = isEditing && content.length === 0;

  useEffect(() => {
    setIsLocked(!user && data.password);
  }, [user, data.password]);

  useEffect(() => {
    if (!user) {
      return;
    }

    setIsBeingEditedBy(isEditing ? user.uid : null);
  }, [user, isEditing]);

  useEffect(() => {
    if (data.title) {
      setTitle(data.title);
    }

    if (data.hero) {
      setHero(data.hero);
    }

    if (data.content) {
      setContent([...deepCopy(data.content)]);
    }
  }, [data, isEditing]);

  useEffect(() => {
    const onKeyDown = (e) => {
      if (isHotkey('mod+s', e)) {
        e.preventDefault();
        onSave();
      }
    };

    isEditing && window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [isEditing, content, title, hero]);

  if (isLocked) {
    return <UnlockForm error={unlockFormError} onSubmit={(password) => onUnlockForm({ pageId, password })} />;
  }

  return (
    <div className={styles.container}>
      {user && isEditing && (
        <EditBar title={title} onSave={onSave} onClose={onEditClose} onPassword={() => setVisibleOverlay('password')} />
      )}

      {user && pageId && !isEditing && (
        <div className={styles.editButtonContainer}>
          <Button
            filled
            icon={data.isBeingEditedBy ? <LockIcon /> : <EditIcon />}
            onClick={data.isBeingEditedBy ? () => setVisibleOverlay('edit') : () => setIsEditing(true)}
          >
            {data.isBeingEditedBy ? staticText.beingEdited : staticText.edit}
          </Button>
        </div>
      )}

      <div style={{ fontFamily }}>
        <Hero isEditing={isEditing} setHero={setHero} hero={hero} />
        <PageTitle isEditing={isEditing} setTitle={setTitle} title={title} />
        <Content isEditing={isEditing} setContent={setContent} content={content} />
        {showTemplatesContainer && <TemplatesContainer setContent={setContent} />}
      </div>

      {visibleOverlay === 'password' && (
        <PasswordProtectOverlay
          hasPassword={!!data.password}
          onClose={() => setVisibleOverlay(null)}
          onRemove={onPasswordProtectRemove}
          onSave={onPasswordProtectSave}
        />
      )}
      {visibleOverlay === 'save' && (
        <WantToSaveOverlay onClose={() => setVisibleOverlay(null)} onSave={onOverlaySave} onUndo={onUndo} />
      )}
      {visibleOverlay === 'edit' && (
        <EditAnywayOverlay
          onClose={() => setVisibleOverlay(null)}
          onEdit={() => {
            setIsEditing(true);
            setVisibleOverlay(null);
          }}
        />
      )}
    </div>
  );

  function onEditClose() {
    if (title === data.title && hero === data.hero && deepCompare(content, data.content)) {
      setIsEditing(false);
    } else {
      setVisibleOverlay('save');
    }
  }

  async function onPasswordProtectSave(password) {
    const { error, message, hash } = await postFetch('/api/getpassword', {
      password,
    });

    if (!error) {
      await savePage({ password: hash });

      setNotification({
        message: staticText.passwordSaved,
        onDone: () => setNotification(null),
      });

      return setVisibleOverlay(null);
    }

    return captureException(message);
  }

  async function onPasswordProtectRemove() {
    await savePage({ password: null });

    setNotification({
      message: staticText.passwordRemoved,
      onDone: () => setNotification(null),
    });

    return setVisibleOverlay(null);
  }

  async function onOverlaySave() {
    await onSave();
    setVisibleOverlay(null);
    setIsEditing(false);
  }

  async function onSave() {
    if (!content || !title) {
      return Promise.reject('De titel en content zijn verplicht');
    }

    await savePage({ content, title, hero, lastEditBy: user.uid });

    setNotification({
      message: staticText.saved,
      onDone: () => setNotification(null),
    });

    return Promise.resolve();
  }

  function onUndo() {
    setContent([...deepCopy(data.content || [])]);
    setIsEditing(false);
    setVisibleOverlay(null);

    setNotification({
      message: staticText.undone,
      onDone: () => setNotification(null),
    });
  }

  async function onUnlockForm({ pageId, password }) {
    setUnlockFormError(null);

    const { error, message } = await postFetch('/api/checkpassword', {
      pageId,
      password,
    });

    if (!error) {
      return setIsLocked(false);
    }

    return setUnlockFormError(message);
  }

  function postFetch(endpoint, body) {
    return fetch(endpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    }).then((r) => r.json());
  }
};
