import { Button } from 'components/Button'
import { Image } from 'components/Image'
import { MediaLibrary } from 'components/MediaLibrary'
import { Overlay } from 'components/Overlay'
import { S, M, L } from 'shared/sizes'
import { SaveIcon } from 'components/Icons'
import { Select } from 'components/Select'
import { staticText } from 'content/staticText'
import { useState } from 'react'
import { WithEditButton } from 'components/WithEditButton'
import styles from './DoDontOverlay.module.css'

export const DoDontOverlay = ({ onClose, doImage, dontImage, size, onSave }) => {
  const [localSize, setLocalSize] = useState(size || S)
  const [localDoImage, setLocalDoImage] = useState(doImage)
  const [localDontImage, setLocalDontImage] = useState(dontImage)
  const [onMediaUseFn, setOnMediaUseFn] = useState(null)

  const sizeOptions = [
    { value: S, label: staticText.small },
    { value: M, label: staticText.medium },
    { value: L, label: staticText.large },
  ]

  const onMediaUseFns = {
    do: (value) => {
      setLocalDoImage(value)
      setOnMediaUseFn(null)
    },
    dont: (value) => {
      setLocalDontImage(value)
      setOnMediaUseFn(null)
    },
  }
  const onMediaUse = onMediaUseFns[onMediaUseFn]

  return (
    <Overlay onClose={onClose}>
      {onMediaUse ? (
        <MediaLibrary filterFn={({ contentType }) => contentType.indexOf('image') > -1} onUse={onMediaUse} />
      ) : (
        <>
          <div className={styles.group}>
            <Select
              label={staticText.size}
              onChange={(e) => setLocalSize(e.target.value)}
              options={sizeOptions}
              value={localSize}
            />
          </div>
          <div className={styles.imagesGroup}>
            <WithEditButton onClick={() => setOnMediaUseFn('do')}>
              <Image content={localDoImage} />
            </WithEditButton>

            <WithEditButton onClick={() => setOnMediaUseFn('dont')}>
              <Image content={localDontImage} />
            </WithEditButton>
          </div>

          <Button
            icon={<SaveIcon />}
            onClick={() => onSave({ doImage: localDoImage, dontImage: localDontImage, size: localSize })}
          >
            Opslaan
          </Button>
        </>
      )}
    </Overlay>
  )
}
