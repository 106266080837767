import { MaxWidth } from 'components/MaxWidth'
import { pageTemplates } from 'content/pageTemplates'
import { RichText } from 'components/RichText'
import { S } from 'shared/sizes'
import { staticText } from 'content/staticText'
import { TemplateIcon } from 'components/Icons'
import styles from './Templates.module.css'

export const TemplatesContainer = ({ setContent }) => (
  <MaxWidth size={S}>
    <div className={styles.headingContainer}>
      <RichText>
        <h2>{staticText.fromTemplate}</h2>
      </RichText>
    </div>
    <Templates onClick={setContent} />
  </MaxWidth>
)

const Templates = ({ onClick }) => (
  <div className={styles.templates}>
    {pageTemplates.map(({ title, content, description }) => (
      <TemplateCard description={description} key={title} onClick={() => onClick(content)} title={title} />
    ))}
  </div>
)

const TemplateCard = ({ title, description, onClick, ...props }) => (
  <button className={styles.card} onClick={onClick} {...props}>
    <TemplateIcon />
    <span className={styles.inner}>
      <span className={styles.title}>{title}</span>
      <span>{description}</span>
    </span>
  </button>
)
