import { Image } from "components/Image";
import { MaxWidth } from "components/MaxWidth";
import styles from "./Cards.module.css";

export const Cards = ({ content }) => {
  const { backgroundColor, cards = [] } = content;

  const cardsInThreeColumns = cards.reduce(
    (acc, cur, index) => {
      const arrayIndex = getArrayIndexForIndex(index);
      acc[arrayIndex].push(cur);
      return acc;
    },
    [[], [], []]
  );

  return (
    <div className={styles.container} style={{ backgroundColor }}>
      <MaxWidth>
        <div className={styles.cardsContainer}>
          {cardsInThreeColumns.map((cardArray, index) => (
            <div className={styles.cardsColumn} key={index}>
              {cardArray.map((card, index) => (
                <Card key={index} {...card} />
              ))}
            </div>
          ))}
        </div>
      </MaxWidth>
    </div>
  );
};

const Card = ({ description, href, image, title }) => (
  <a className={styles.card} href={href}>
    <div className={styles.content}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.description}>{description}</p>
    </div>
    <Image content={image} maxWidth={300} />
  </a>
);

function getArrayIndexForIndex(index) {
  if (((index + 1) / 3).toString().indexOf(333) > -1) {
    return 0;
  }

  if (((index + 1) / 3).toString().indexOf(666) > -1) {
    return 1;
  }

  return 2;
}
