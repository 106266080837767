import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { LockIcon } from 'components/Icons'
import { MaxWidth } from 'components/MaxWidth'
import { RichText } from 'components/RichText'
import { S } from 'shared/sizes'
import { staticText } from 'content/staticText'
import { useState } from 'react'
import styles from './UnlockForm.module.css'

export const UnlockForm = ({ error, onSubmit }) => {
  const [value, setValue] = useState('')

  const onFormSubmit = (e) => {
    e.preventDefault()
    onSubmit(value)
  }

  return (
    <MaxWidth size={S}>
      <div className={styles.container}>
        <div className={styles.iconContainer}>
          <LockIcon />
        </div>
        <div className={styles.textContainer}>
          <RichText>
            <h2>{staticText.pageLockedTitle}</h2>
            <p>{staticText.pageLockedText}</p>
          </RichText>
        </div>

        <form className={styles.form} onSubmit={onFormSubmit}>
          <div className={styles.inputContainer}>
            <Input type="password" label="Wachtwoord" value={value} onChange={(e) => setValue(e.target.value)} />
            {error && <div className={styles.error}>{error}</div>}
          </div>
          <Button>{staticText.unlock}</Button>
        </form>
      </div>
    </MaxWidth>
  )
}
