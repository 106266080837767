import { Button } from 'components/Button'
import { L } from 'shared/sizes'
import { LockIcon } from 'components/Icons'
import { MaxWidth } from 'components/MaxWidth'
import { staticText } from 'content/staticText'
import { useEffect, useState } from 'react'
import styles from './EditBar.module.css'

export const EditBar = ({ title, onSave, onClose, onPassword }) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    document.body.style.paddingTop = '64px'
    setIsOpen(true)

    return () => (document.body.style.paddingTop = '0px')
  }, [])

  return (
    <div
      className={styles.container}
      style={isOpen ? { transform: 'translateY(0)' } : { transform: 'translateY(-100%)' }}
    >
      <MaxWidth size={L}>
        <div className={styles.inner}>
          <Button icon={<LockIcon />} onClick={onPassword}>
            {staticText.passwordProtect}
          </Button>

          <div className={styles.group}>
            {staticText.editingPage}&nbsp;<strong>{title}</strong>
          </div>
          <div className={styles.group}>
            <Button onClick={onSave} filled>
              {staticText.save}
            </Button>
            <Button onClick={onClose}>{staticText.close}</Button>
          </div>
        </div>
      </MaxWidth>
    </div>
  )
}
