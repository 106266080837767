import { Button } from 'components/Button'
import { DeleteIcon, ImageIcon, SaveIcon } from 'components/Icons'
import { Input } from 'components/Input'
import { Select } from 'components/Select'
import { MediaLibrary } from 'components/MediaLibrary'
import { Overlay } from 'components/Overlay'
import { useState } from 'react'
import styles from './LinkOverlay.module.css'

export const LinkOverlay = ({ onClose, onSave, onRemove, url }) => {
  const [showMediaLibrary, setShowMediaLibrary] = useState(false)
  const [localUrl, setLocalUrl] = useState(url)
  const [localTarget, setLocalTarget] = useState('_self')

  const targetOptions = [
    { value: '_self', label: 'Open link in zelfde tabblad' },
    { value: '_blank', label: 'Open link in een nieuw tabblad' },
  ]

  const onMediaUse = ({ url }) => {
    setLocalUrl(url)
    setShowMediaLibrary(false)
  }

  const onSaveClick = () => onSave({ url: localUrl, target: localTarget })

  return (
    <Overlay onClose={onClose}>
      {showMediaLibrary ? (
        <MediaLibrary onUse={onMediaUse} />
      ) : (
        <>
          <div className={styles.group}>
            <div className={styles.inputContainer}>
              <Input
                label={'Url'}
                onChange={(e) => setLocalUrl(e.target.value)}
                placeholder={'Bijvoorbeeld https://www.example.com/'}
                type="text"
                value={localUrl || ''}
              />
            </div>

            <Button icon={<ImageIcon />} onClick={() => setShowMediaLibrary(true)}>
              Bestand kiezen
            </Button>
          </div>
          <div className={styles.group}>
            <Select
              label="Openen in nieuw tabblad?"
              onChange={(e) => setLocalTarget(e.target.value)}
              options={targetOptions}
              value={localTarget}
            />
          </div>
          <div className={styles.group}>
            <Button icon={<SaveIcon />} onClick={onSaveClick}>
              Opslaan
            </Button>
            {url && (
              <Button icon={<DeleteIcon />} onClick={onRemove}>
                Link verwijderen
              </Button>
            )}
          </div>
        </>
      )}
    </Overlay>
  )
}
