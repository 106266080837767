import { Button } from 'components/Button'
import styles from './Buttons.module.css'

export const Buttons = ({ content }) => (
  <div className={styles.container}>
    {content.items.map(({ href, label }, index) => (
      <Button gray key={index} href={href}>
        {label}
      </Button>
    ))}
  </div>
)
