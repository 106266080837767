import { AddIcon, ToggleIcon, DeleteIcon, InfoIcon, SaveIcon } from 'components/Icons'
import { Button } from 'components/Button'
import { ColorPicker } from 'components/ColorPicker'
import { deepCopy } from 'shared/deepCopy'
import { FULL } from 'shared/sizes'
import { Image } from 'components/Image'
import { Input } from 'components/Input'
import { MediaLibrary } from 'components/MediaLibrary'
import { Overlay } from 'components/Overlay'
import { useState } from 'react'
import { WithEditButton } from 'components/WithEditButton'
import cx from 'classnames'
import styles from './CardsOverlay.module.css'

export const CardsOverlay = ({ backgroundColor, cards, onClose, onSave }) => {
  const [localBackgroundColor, setLocalBackgroundColor] = useState(backgroundColor)
  const [localCards, setLocalCards] = useState(cards)

  const onCardChange = (value, index) => {
    const newItems = deepCopy(localCards)
    newItems[index] = { ...newItems[index], ...value }
    setLocalCards(newItems)
  }

  const addCard = () => {
    const newItems = deepCopy(localCards)
    newItems.push(newItems[newItems.length - 1])
    setLocalCards(newItems)
  }

  const deleteCard = (index) => {
    const newItems = deepCopy(localCards)
    newItems.splice(index, 1)
    setLocalCards(newItems)
  }

  return (
    <Overlay onClose={onClose}>
      <div className={styles.group}>
        <ColorPicker onChange={setLocalBackgroundColor} value={localBackgroundColor}>
          <Input
            label="Achtergrondkleur"
            onChange={(e) => setLocalBackgroundColor(e.target.value)}
            type="text"
            value={localBackgroundColor}
          />
        </ColorPicker>
      </div>
      <div className={styles.group}>
        {localCards.map((card, index) => (
          <CardRow
            key={index}
            onChange={(value) => onCardChange(value, index)}
            onDelete={() => deleteCard(index)}
            {...card}
          />
        ))}
      </div>
      <div className={styles.group}>
        <Button icon={<AddIcon />} onClick={addCard}>
          Kaart toevoegen
        </Button>
      </div>
      <Button
        icon={<SaveIcon />}
        onClick={() => onSave({ cards: localCards, backgroundColor: localBackgroundColor, size: FULL })}
      >
        Opslaan
      </Button>
    </Overlay>
  )
}

const CardRow = ({ description, href, image, onChange, onDelete, title }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [showMediaLibrary, setShowMediaLibrary] = useState(false)

  const onMediaUse = (image) => {
    onChange({ image })
    setShowMediaLibrary(false)
  }

  return (
    <>
      <div className={styles.row}>
        <div className={cx(styles.header, { [styles.headerOpen]: isOpen })} onClick={() => setIsOpen(!isOpen)}>
          <div>{title}</div>
          <ToggleIcon />
        </div>
        <div className={cx(styles.content, { [styles.contentOpen]: isOpen })}>
          <Input
            placeholder="Bijv: https://jouw.lasagna.app/fmew23df"
            label="Link"
            onChange={(e) => onChange({ href: e.target.value })}
            type="text"
            value={href}
          />
          <Input label="Titel" onChange={(e) => onChange({ title: e.target.value })} type="text" value={title} />
          <Input
            label="Beschrijving"
            onChange={(e) => onChange({ description: e.target.value })}
            type="text"
            value={description}
          />
          <WithEditButton onClick={() => setShowMediaLibrary(true)}>
            <Image content={image} />
          </WithEditButton>
          <div className={styles.imageInfo}>
            <InfoIcon />
            <div>Minimale afmetingen: 285 x 160 pixels</div>
          </div>

          <Button icon={<DeleteIcon />} onClick={onDelete}>
            Kaart verwijderen
          </Button>
        </div>
      </div>
      {showMediaLibrary && (
        <Overlay onClose={() => setShowMediaLibrary(false)}>
          <MediaLibrary filterFn={({ contentType }) => contentType.indexOf('image') > -1} onUse={onMediaUse} />
        </Overlay>
      )}
    </>
  )
}
