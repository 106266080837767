import { AddIcon, DeleteIcon, SaveIcon } from "components/Icons";
import { Button } from "components/Button";
import { ColorPicker } from "components/ColorPicker";
import { deepCopy } from "shared/deepCopy";
import { hexToCmyk } from "shared/colors";
import { Input } from "components/Input";
import { Overlay } from "components/Overlay";
import { S, M, L } from "shared/sizes";
import { Select } from "components/Select";
import { staticText } from "content/staticText";
import { useState } from "react";
import styles from "./ColorsOverlay.module.css";

export const ColorsOverlay = ({ onClose, items, size, onSave }) => {
  const [localSize, setLocalSize] = useState(size || S);
  const [localItems, setLocalItems] = useState(items);

  const sizeOptions = [
    { value: S, label: staticText.small },
    { value: M, label: staticText.medium },
    { value: L, label: staticText.large },
  ];

  const onColorRowChange = (value, index) => {
    const newItems = deepCopy(localItems);
    newItems[index] = { ...newItems[index], ...value };
    setLocalItems(newItems);
  };

  const addColorRow = () => {
    const newItems = deepCopy(localItems);
    newItems.push(newItems[newItems.length - 1]);
    setLocalItems(newItems);
  };

  const deleteColorRow = (index) => {
    const newItems = deepCopy(localItems);
    newItems.splice(index, 1);
    setLocalItems(newItems);
  };

  return (
    <Overlay onClose={onClose}>
      <div className={styles.group}>
        <Select
          label={staticText.size}
          onChange={(e) => setLocalSize(e.target.value)}
          options={sizeOptions}
          value={localSize}
        />
      </div>
      <div className={styles.group}>
        {localItems.map((item, index) => (
          <ColorRow
            key={index}
            onChange={(value) => onColorRowChange(value, index)}
            onDelete={() => deleteColorRow(index)}
            {...item}
          />
        ))}

        <Button icon={<AddIcon />} onClick={addColorRow}>
          Kleur toevoegen
        </Button>
      </div>
      <Button
        icon={<SaveIcon />}
        onClick={() => onSave({ items: localItems, size: localSize })}
      >
        Opslaan
      </Button>
    </Overlay>
  );
};

const ColorRow = ({ hex, pantone, cmyk, cmyku, title, onChange, onDelete }) => (
  <div className={styles.colorRow}>
    <div className={styles.colorPreview} style={{ backgroundColor: hex }} />

    <Input
      label="Label"
      onChange={(e) => onChange({ title: e.target.value })}
      type="text"
      value={title}
    />
    <ColorPicker onChange={(hex) => onChange({ hex })} value={hex}>
      <Input
        label="HEX"
        onChange={(e) => onChange({ hex: e.target.value })}
        type="text"
        value={hex}
      />
    </ColorPicker>
    <Input
      label="CMYK (c)"
      onChange={(e) => onChange({ cmyk: e.target.value })}
      placeholder={hexToCmyk(hex).join(", ")}
      type="text"
      value={cmyk}
    />
    <Input
      label="CMYK (u)"
      onChange={(e) => onChange({ cmyku: e.target.value })}
      placeholder={hexToCmyk(hex).join(", ")}
      type="text"
      value={cmyku}
    />
    <Input
      label="Pantone"
      onChange={(e) => onChange({ pantone: e.target.value })}
      type="text"
      value={pantone}
    />

    <div>
      <button className={styles.deleteButton} onClick={onDelete}>
        <span className={styles.deleteButtonInner}>
          <DeleteIcon />
        </span>
      </button>
    </div>
  </div>
);
