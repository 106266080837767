import { useSlate } from 'slate-react'
import cx from 'classnames'
import styles from './Toolbar.module.css'

export const Toolbar = ({ children }) => (
  <div className={`${styles.toolbarWrapper} toolbar`}>
    <div className={styles.toolbar}>{children}</div>
  </div>
)

export const ToolbarGroup = ({ children }) => <div className={styles.toolbarGroup}>{children}</div>

export const ToolbarButton = ({ icon, isActive, onClick, title, isDisabled }) => {
  const editor = useSlate()

  const active = isActive(editor)
  const disabled = isDisabled && isDisabled(editor)

  return (
    <span
      className={cx(styles.toolbarButton, { [styles.isActive]: active, [styles.isDisabled]: disabled })}
      title={title}
      onMouseDown={(e) => {
        e.preventDefault()
        !disabled && onClick(e)
      }}
    >
      <span className={styles.toolbarButtonInner}>{icon}</span>
    </span>
  )
}
