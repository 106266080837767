import { CloseIcon, CheckIcon } from 'components/Icons'
import { Image } from 'components/Image'
import { LightBox } from 'components/LightBox'
import cx from 'classnames'
import styles from './DoDont.module.css'

export const DoDont = ({ content }) => {
  const { doImage, dontImage } = content

  return (
    <div className={styles.container}>
      <DoDontBlock isDo image={doImage} />
      <DoDontBlock image={dontImage} />
    </div>
  )
}

const DoDontBlock = ({ image, isDo }) => (
  <div className={cx(styles.block, { [styles.blockDont]: !isDo })}>
    <div className={cx(styles.blockIcon, { [styles.blockIconDont]: !isDo })}>
      {isDo ? <CheckIcon /> : <CloseIcon />}
    </div>

    <LightBox>
      <Image content={image} />
    </LightBox>
  </div>
)
